import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Partners.css";

import img1 from "../assets/img/partImg/img1.png";
import img2 from "../assets/img/partImg/img2.png";
import img3 from "../assets/img/partImg/img3.png";
import img4 from "../assets/img/partImg/img4.png";
import img5 from "../assets/img/partImg/img5.png";
import img6 from "../assets/img/partImg/img6.png";
import img7 from "../assets/img/partImg/img7.jpg";

const Partners = () => {
  return (
    <div className="partners">
      <div className="partners__container container">
      <hr className='hrc' data-aos="fade-up" data-aos-duration="1000" />
      <h2 data-aos="fade-up" data-aos-duration="1200">ПАРТНЕРЫ</h2>
        <div className="partners__items-block">
          <div className="partners__items">

            <Swiper
              autoHeight={false}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              speed={1000}
              slidesPerView={4}
              navigation
              pagination={{ clickable: true }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                780: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                880: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
            >

              <SwiperSlide>
                <div className="partners__item">
                  <div className="partners__img">
                    <img src={img1} alt="" />
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="partners__item">
                  <div className="partners__img">
                    <img src={img2} alt="" />
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="partners__item">
                  <div className="partners__img">
                    <img src={img3} alt="" />
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="partners__item">
                  <div className="partners__img">
                    <img src={img4} alt="" />
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="partners__item">
                  <div className="partners__img">
                    <img src={img5} alt="" />
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="partners__item">
                  <div className="partners__img">
                    <img src={img6} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="partners__item">
                  <div className="partners__img">
                    <img src={img7} alt="" />
                  </div>
                </div>
              </SwiperSlide>


            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
