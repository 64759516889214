import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classes from './HomeSerBlock.module.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

function HomeSerBlock({img, title, subtitle1, subtitle2, subtitle3, subtitle4, dataAos, uu }) {
  
  useEffect(() => {
    AOS.init();
  }, [])

  return (
      <div data-aos={dataAos} data-aos-duration="1200" className={classes.block}>
        <img src={img} alt="1"/>
        <h4>{title}</h4>
          <div className={classes.subtitle}>
            <li>{subtitle1}</li>
            <li>{subtitle2}</li>
            <li>{subtitle3}</li>
          </div>
        <Link to={'services'}><button className={classes.btn}>Узнать больше</button></Link>
    </div>
  )
}

export default HomeSerBlock;