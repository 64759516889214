import React from 'react';
import classes from './Header.module.scss';
import logo from '../../assets/img/logo.png';
import { TbBrandTelegram, TbBrandInstagram, TbBrandWhatsapp } from 'react-icons/tb'
import { MdOutlineMailOutline } from 'react-icons/md'
import { Link } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';

function Header() {
    const menu = [
        { id: 0, value: "ГЛАВНАЯ", href: "/" },
        { id: 1, value: "О НАС", href: "aboutUs" },
        { id: 2, value: "УСЛУГИ", href: "services" },
        { id: 2, value: "КОНТАКТЫ", href: "contacts" },
    ];
    const [menuActive, setMenuActive] = React.useState(false);
    return (
        <header className={classes.header}>
            <div className={classes.block}>
                <div className={classes.top}>
                    <div className={classes.container}>
                        <div className={classes.intro}>
                            <div className={classes.right}>
                                <img src={logo} alt="LOGO" />
                            </div>
                            <div className={classes.left}>
                                <a href="mailto:uright.2023@gmail.com"><MdOutlineMailOutline className={classes.icons} /></a>
                                <a href="https://t.me/u_right"><TbBrandTelegram className={classes.icons} /></a>
                                <a href="https://instagram.com/u_right_lf?igshid=YmMyMTA2M2Y="><TbBrandInstagram className={classes.icons} /></a>
                                <a href="https://wa.me/996990606607"><TbBrandWhatsapp className={classes.icons} /></a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={classes.bottom}>
                    <div className={classes.container}>
                        <div className={classes.links}>
                            <Link to='/'><div>ГЛАВНАЯ</div></Link>
                            <Link to={'aboutUs'}><div>О НАС</div></Link>
                            <Link to={'services'}><div>УСЛУГИ</div></Link>
                            <Link to={'contacts'}><div>КОНТАКТЫ</div></Link>
                        </div>
                    </div>
                    <div className={classes.menu__btn} onClick={() => setMenuActive(!menuActive)}>
                        <span className={classes.senond} />
                    </div>
                </div>
                <Menu
                    active={menuActive}
                    setActive={setMenuActive}
                    header={"Menu"}
                    items={menu}
                />

            </div>

        </header>
    )
}

export default Header;