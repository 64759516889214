import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Footer from '../layout/Footer'
import Header from '../layout/Header'
import AboutUs from '../pages/aboutUs'
import Contact from '../pages/contact'
import Home from '../pages/home'
import Services from '../pages/services'


function Router() {

    return (
        <>
            <Header/>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='aboutUs' element={<AboutUs />} />
                    <Route path='services' element={<Services />} />
                    <Route path='contacts' element={<Contact />} />
                </Routes>
            <Footer/>
        </>
    )
}

export default Router