import React from 'react';
import { Helmet } from 'react-helmet-async';
import About from '../../components/About';
import classes from './about.module.scss';

function AboutUs() {
  return (
    <div className={classes.about}>


      <Helmet>
        <title>Юрайт</title>
        <meta name="description" content="Юридическая компания Юрайт" />
        <link rel="canonical" href="/aboutUs" />
      </Helmet>
      <About />
    </div>
  )
}

export default AboutUs;