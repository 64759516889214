import React from 'react';
import classes from './Footer.module.scss';
import logo from '../../assets/img/logo.png';
import { TbBrandTelegram, TbBrandInstagram, TbBrandWhatsapp } from 'react-icons/tb';
import { MdOutlineMailOutline } from 'react-icons/md'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer >
      <div className={classes.container}>
        <div className={classes.block}>

          <div className={classes.right}>
            <img src={logo} alt="LOGO" />
            <p className={classes.subtitle}>Юридическая компания «Ю-райт» </p>
          </div>

          <div className={classes.left}>
            <div className={classes.links}>
              <h4 className={classes.title}>БЫСТРЫЕ ССЫЛКИ</h4>
              <br />
              <Link to='/'><div>ГЛАВНАЯ</div></Link>
              <Link to={'aboutUs'}><div>О НАС</div></Link>
              <Link to={'services'}><div>УСЛУГИ</div></Link>
              <Link to={'contacts'}><div>КОНТАКТЫ</div></Link>
            </div>

            <div className={classes.contact}>
              <h4 className={classes.title}>КОНТАКТЫ</h4>
              <br />  
              <div className={classes.info}>
                <p className={classes.subtitle}>Whatsapp: + 996 990 606 607</p>
                <p className={classes.subtitle}>Telegram: +996 509 606 607</p>
                <p className={classes.subtitle}>Телефон: +996 776 606 607</p>
                <p className={classes.subtitle}>Кыргызская Республика, г.Бишкек, ул М.Горького, 128, <br/> 2-этаж, 204-офис</p>
                <div className={classes.icon}>
                <a href="mailto:uright.2023@gmail.com"><MdOutlineMailOutline className={classes.icons} /></a>
                  <a href="https://t.me/u_right"><TbBrandTelegram className={classes.icons} /></a>
                  <a href="https://instagram.com/u_right_lf?igshid=YmMyMTA2M2Y="><TbBrandInstagram className={classes.icons} /></a>
                  <a href="https://wa.me/996990606607"><TbBrandWhatsapp className={classes.icons} /></a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </footer>
  )
}

export default Footer;