import React from 'react'
import classes from './contact.module.scss'
import Contacts from '../../components/Contacts';
import { Helmet } from 'react-helmet-async';
function Contact() {
  return (
    <div className={classes.about}>
      <Helmet>
        <title>Юрайт контакты</title>
        <meta name="description" content="Контакты юридической компании Юрайт" />
        <link rel="canonical" href="/contacts" />
      </Helmet>
      <Contacts />
    </div>
  )
}
export default Contact;