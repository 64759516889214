import React from 'react'
import { Link } from 'react-router-dom'
import './Menu.css'

const Menu = ({ header, items, active, setActive }) => {
    return (
        <div className={active ? 'menu active' : 'menu'} onClick={() => setActive(false)}>
            <div className="menu__content">
                <div className="menu__header">{header}</div>
                <ul>
                    {items.map(item =>
                        <li>
                            <Link key={item.id} to={item.href}>{item.value}</Link>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default Menu;