import React from 'react';
import prosData from '../../assets/localData/prosData';
import About from '../../components/About';
import HomeSerBlock from '../../components/HomeSerBlock';
import Main from '../../components/Main';
import ProsBlock from '../../components/ProsBlock';
import classes from './home.module.scss';
import img1 from '../../assets/img/1.png';
import img2 from '../../assets/img/2.png';
import img3 from '../../assets/img/3.png';
import img4 from '../../assets/img/4.png';
import Contacts from '../../components/Contacts';
import Partners from '../../Partners/Partners';
import RecallBlock from '../../components/RecallBlock';
import recallData from '../../assets/localData/recallData';
import { Helmet } from 'react-helmet-async';

function Home() {
  return (
    <div className={classes.home}>

      <Helmet>
        <title>Ю-райт</title>
        <meta name="description" content="ОсОО Юридическая компания «Ю-райт»"/>
        <link rel="canonical" type="" href="/"/>
      </Helmet>

      <Main />
      <About />
      <div className={classes.content}>
        <div className={classes.container}>
          <div className={classes.pros}>
            {prosData.map((obj, index) => {
              return <ProsBlock obj={obj} key={index} />
            })}
          </div>
        </div>
      </div>

      <div className={classes.services}>
        <div className={classes.container}>
          <hr data-aos="fade-up" data-aos-duration="1000" />
          <h2 data-aos="fade-up" data-aos-duration="1200">НАШИ УСЛУГИ</h2>
          <div className={classes.block}>
            <HomeSerBlock
              uu
              img={img1}
              title='Юридическое сопровождение бизнеса / Юридические услуги для юридических лиц:'
              subtitle1='Регистрация / перерегистрация юридических лиц:'
              subtitle2='ОсОО, ОАО, ЗАО, ОсДО:'
              subtitle3='Индивидуальный предприниматель;'
              dataAos='zoom-in'
            />
            <HomeSerBlock
              uu
              img={img2}
              title='Юридическое сопровождение физического лица / Юридические услуги для физических лиц:'
              subtitle1='Составление договоров любой сложности;'
              subtitle2='Экспертиза договоров ;'
              subtitle3='Составление и экспертиза любых правовых...'
              dataAos='zoom-in'
            />
            <HomeSerBlock
              img={img3}
              title='Юридическое сопровождение иностранного лица / Юридические услуги для иностранных лиц:'
              subtitle1='Регистрация  иностранных граждан;'
              subtitle2='Продление регистрации иностранного лица;'
              subtitle3='Получение ПИН;'
              dataAos='zoom-in'
            />
            <HomeSerBlock
              uu
              img={img4}
              title='Бухгалтерское сопровождение бизнеса / Бухгалтерсикие услуги для юридических лиц и ИП / Бухгалтерское сопровождение:'
              subtitle1='ведение бухгалтерского учёта в соответствии с действующим законодательством...'
              subtitle2='формирование и сдаче ежемесячных/ежеквартальных/...'
              subtitle3='консультации по налогообложению;'
              dataAos='zoom-in'
            />
          </div>
        </div>
      </div>

      <Partners />

      <div className={classes.recall}>
        <div className={classes.container}>
          <hr data-aos="fade-up" data-aos-duration="1000" />
          <h2 data-aos="fade-up" data-aos-duration="1200">НАШИ УСЛУГИ</h2>
          <div className={classes.pross}>
            {recallData.map((obj, index) => {
              return <RecallBlock obj={obj} key={index} />
            })}
          </div>
        </div>
      </div>

      <Contacts />

    </div>
  )
}

export default Home;