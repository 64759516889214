import React from 'react';
import classes from './About.module.scss'

function About() {
  return (
    <div className={classes.about}>
        <div className={classes.block}>
            <hr data-aos="zoom-in" data-aos-duration="1000"/>
            <h2 data-aos="zoom-in" data-aos-duration="1500">О КОМПАНИИ</h2>
            <p data-aos="zoom-in" data-aos-duration="1500">«Юридическая компания «Ю-райт»  – это сплочённый коллектив юристов, бухгалтеров и налоговых консультантов. 100% наших сотрудников молодые, предприимчивые и стремящиеся к профессиональному и личностному росту специалистов.
Наши основные ценности - максимальная ориентированность на потребности клиента и нацеленность на результат. Наши главные преимущества - высокая компетенция в сферах специализации, профессиональная команда единомышленников, лояльная ценовая политика и безупречная деловая репутация. 
Знание и опыт наших специалистов позволяют фирме эффективно защищать интересы наших клиентов по делам любого уровня сложности, а также быстро и квалифицированно разрешать все задачи, возникающие у наших клиентов. 
Одним из основных принципов деятельности нашей юридической компании является соблюдение и сохранение клиентской тайны. Строгое соблюдение конфиденциальности является необходимым условием доверия клиентов нашей фирме.</p>
        </div>
    </div>
  )
}

export default About;