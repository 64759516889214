import React, { useEffect } from 'react';
import classes from './Main.module.scss';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Main() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className={classes.main}>
      <div className={classes.block}>
        <h1 data-aos="fade-up" data-aos-duration="1000">ВСЕГДА ДЕЛАЙ БОЛЬШЕ <br/>ВОЗМОЖНОГО</h1>
        <Link to={'/services'}><button data-aos-duration="500" data-aos="fade-down" className={classes.button}>НАШИ УСЛУГИ</button></Link>
      </div>
    </div>
  )
}

export default Main;