import React from 'react';
import classes from './Contacts.module.scss';
import { MdOutlineEmail } from 'react-icons/md'
import { AiOutlineContacts } from 'react-icons/ai'
import { BiTimeFive } from 'react-icons/bi'
import { HiOutlineLocationMarker } from 'react-icons/hi'

function Contacts() {
  return (
    <div className={classes.contact}>
    <div className={classes.container}>
      <hr className={classes.hrc} data-aos="fade-up" data-aos-duration="1000" />
      <h2 data-aos="fade-up" data-aos-duration="1200">КОНТАКТЫ</h2>
      <div className={classes.info}>
        <div className={classes.contacts}>

          <div data-aos='zoom-in' className={classes.items}>
            <MdOutlineEmail size={64}/>
            <div className={classes.email}>
            <h4>ПОЧТА</h4>
            <span>uright.2023@gmail.com</span>
            </div>
          </div>
          
          <div data-aos='zoom-in' className={classes.items}>
            <AiOutlineContacts size={64}/>
            <div className={classes.email}>
            <h4>ТЕЛЕФОН</h4>
            <div className={classes.spans}>
            <span>Whatsapp: + 996 990 606 607</span>
            <span>Telegram: +996 509 606 607</span>
            <span>Телефон: +996 776 606 607</span>
            </div>
            </div>
          </div>

          <div data-aos='zoom-in'  className={classes.items}>
            <BiTimeFive size={64}/>
            <div className={classes.email}>
            <h4>РЕЖИМ РАБОТЫ</h4>
            <span>ПН - ПТ  09.00-18.00</span>
            </div>
          </div>

          <div data-aos='zoom-in' className={classes.items}>
            <HiOutlineLocationMarker size={114}/>
            <div className={classes.email}>
            <h4>НАШ АДРЕС</h4>
            <span>Кыргызская Республика, г.Бишкек, ул.М.Горького, 128, 2-этаж, 204-офис</span>
            </div>
          </div>

        </div>

        <div data-aos="zoom-in" data-aos-duration="1200" className={classes.maps}>
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2924.7731705031592!2d74.6083586!3d42.8565225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389eb633d11b5c51%3A0xf8acb653aa7779c!2zMi3RjdGC0LDQtiwgMjA0LdC-0YTQuNGBLCAxMjgg0YPQuy4g0JPQvtGA0YzQutC-0LPQviwg0JHQuNGI0LrQtdC6!5e0!3m2!1sru!2skg!4v1679640974084!5m2!1sru!2skg" 
            className={classes.map}
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>

        </div>
      </div>
    </div>
  </div>
  )
}

export default Contacts;