const recallData = [
    {
        recall: 'Уважаемая, Юридическая компания «Ю-райт»! ОсОО «А-виза Трэвел» в лице Генерального директора Бекбоевой Айдай Айбековны, благодарит Юридическую компанию «Ю-райт» за добропорядочность, оперативность и профессионализм в работе. В процессе нашего сотрудничества я была приятно удивлена не только приемлемой ценовой политикой и качеством Вашей продукции, но и высокой культурой общения Вашего персонала.Желаю Вам новых клиентов и стремительного развития карьеры.',
        jobtitle: 'С уважением,',
        name: 'Бекбоева Айдай Айбековна',
    },
    {
        recall: 'Благодарственное письмо за сотрудничество Юридическая компания «Ю-райт». Мы высоко ценим совместную работу с Вашей компанией, стремимся к удовлетворению потребностей гостей. Мы хорошо осознаем, что своими успехами обязаны, прежде всего, компаниям, предлагающим наши услуги, и, в частности, успешному сотрудничеству с Вами. Выражаем надежду на продолжение успешного сотрудничества на благо наших общих интересов, а также на дальнейшее увеличение достигнутых показателей совместной работы. Желаем Вам и Вашей компании успехов и процветания!',
        jobtitle: 'директор ОО "Ново-Троицкая база"',
        name: 'Мураталиева Венера Жамгырчиевна',
    },
    {
        recall: 'Благодарственное письмо Мы, ОсОО «Лидер Трэвел» выражаем искреннюю благодарность Юридической компании «Ю-райт» за профессионализм и качество оказанных услуг. В процессе совместной работы, мы убедились в высокой квалификации Ваших сотрудников, позволяющей успешно решать любые по сложности задачи и проблемы. С искренними пожеланиями здоровья, благополучия и счастья! Надеемся на дальнейшее сотрудничество!',
        jobtitle: 'Генеральный директор:',
        name: 'Махмут уулу Азиз',
    },
    {
        recall: 'Мы выражаем искреннюю благодарность Юридической компании «Ю-райт» за профессионализм и качество оказанных услуг. B процессе совместной работы, мы убедились в высокой квалификации Ваших сотрудников, позволяющей успешно решать любые по сложности задачи и проблемы. С искренними пожеланиями здоровья, благополучия и счастья!',
        jobtitle: 'Председатель ОЮЛ Ассоциации по продвижению здоровья',
        name: 'Айтмурзаев Г. Т',
    },
];
export default recallData;