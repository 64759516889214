import React from 'react';
import classes from './services.module.scss'
import img1 from '../../assets/img/1.png';
import img2 from '../../assets/img/2.png';
import img3 from '../../assets/img/3.png';
import img4 from '../../assets/img/4.png';
import { Helmet } from 'react-helmet-async';

function Services() {
  return (
    <div className={classes.services}>  
      <Helmet>
        <title>Юрайт услуги</title>
        <meta name="description" content="Услуги юридической компании «Ю-райт»"/>
        <link rel="canonical" href="/services"/>
      </Helmet> 

      <div className={classes.container}>
          <hr />
          <h2>НАШИ УСЛУГИ</h2>
          <div className={classes.block}>
          <div data-aos="zoom-in" data-aos-duration="1000" className={classes.items}>
            <img src={img1} alt="1" />
            <h4>Юридическое сопровождение бизнеса / Юридические услуги для юридических лиц:</h4>
            <div className={classes.subtitle}>
              <li>Регистрация / перерегистрация юридических лиц:</li>
              <h5> - ОсОО, ОАО, ЗАО, ОсДО;</h5>
              <h5> - Индивидуальный предприниматель;</h5>
              <h5> - Представительство, Филиал, Объединение Юридических Лиц;</h5>
              <h5> - Учреждение;</h5>
              <h5> - Общественный Фонд, Общественное Объединение;</h5>
              <h5> - Кооператив, Крестьянско-фермерское хозяйство;</h5>
              <h5> - ТСЖ;</h5>
              <li>Реорганизация;</li>
              <li>Ликвидация;</li>
              <li>Лицензирование;</li>
              <li>Составление учредительных документов.</li>
            </div>
          </div>

          <div data-aos="zoom-in" data-aos-duration="1000" className={classes.items}>
            <img src={img2} alt="1" />
            <h4>Юридическое сопровождение физического лица / Юридические услуги для физических лиц:</h4>
            <div className={classes.subtitle}>
              <li>Составление договоров любой сложности;</li>
              <li>Экспертиза договоров ;</li>
              <li>Составление и экспертиза любых правовых документов (исковые заявления, жалобы, претензии);</li>
              <li>Защита  прав и законных интересов в суде.</li>
            </div>
          </div>

          <div  data-aos="zoom-in" data-aos-duration="1000" className={classes.items}>
            <img src={img3} alt="1" />
            <h4>Юридическое сопровождение иностранного лица / Юридические услуги для иностранных лиц:</h4>
            <div className={classes.subtitle}>
              <li>Регистрация иностранных граждан;</li>
              <li>Продление регистрации иностранного лица;</li>
              <li>Получение ПИН.</li>
            </div>
          </div>

          <div data-aos="zoom-in" data-aos-duration="1000" className={classes.items}>
            <img src={img4} alt="1" />
            <h4>Бухгалтерское сопровождение бизнеса / Бухгалтерсикие услуги для юридических лиц и ИП / Бухгалтерское сопровождение:</h4>
            <div className={classes.subtitle}>
              <li>ведение бухгалтерского учёта в соответствии с действующим законодательством  на основании данных, предоставляемых клиентом;</li>
              <li>формирование и сдаче ежемесячных/ ежеквартальных/ ежегодных отчетов, в том числе, налоговые декларации в территориальные органы государственной налоговой службы, социального фонда, национального статистического комитета в соответствии с действующим законодательством на основании данных, предоставляемых клиентом;</li>
              <li>консультации по налогообложению;</li>
              <li>представление интересов клиента в органах государственной налоговой службы, социального фонда, национального статистического комитета при сдаче соответствующих отчетов и проведении проверок.</li>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Services;