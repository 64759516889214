import React from 'react';
import classes from './RecallBlock.module.scss'
import img from '../../assets/img/comment.png'

function RecallBlock({ obj }) {
  return (
    <div data-aos="zoom-in" data-aos-duration="1000" className={classes.block}>
      <img src={img} alt=""/>
      <p className={classes.p}>{obj.recall}</p>
      <p className={classes.p}>{obj.jobtitle}</p>
      <p className={classes.p}>{obj.name}</p>
    </div>
  )
}

export default RecallBlock;