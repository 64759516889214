import React from 'react';
import classes from './ProsBlock.module.scss'

function ProsBlock({ obj }) {
    return (
        <div className={classes.block}>
            <hr className={classes.hr} data-aos="fade-up" data-aos-duration="1000"/>
            <p className={classes.title} data-aos="fade-up" data-aos-duration="1200">{obj.title}</p>
            <p className={classes.subtitle} data-aos="fade-up" data-aos-duration="1200">{obj.subtitle}</p>
        </div>
    )
}

export default ProsBlock;