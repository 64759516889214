const prosData = [
    {
        title: 'КАЧЕСТВЕННЫЙ СЕРВИС',
        subtitle: 'Основной акцент мы делаем на долгосрочном сотрудничестве с клиентами, поэтому мы обеспечиваем  для каждого клиента максимальный  уровень комфорта.',
    },
    {
        title: 'ИНДИВИДУАЛЬНЫЙ ПОДХОД К КАЖДОМУ КЛИЕНТУ',
        subtitle: 'Уникальность каждого клиента диктует правила игры – мы умеем выявлять и удовлетворять покупательские  потребности.',
    },
    {
        title: 'РАБОТА НА РЕЗУЛЬТАТ',
        subtitle: 'Клиент получает максимальный результат, и при этом уровень его фактических затрат не выходит за рамки средне-рыночной цены оказываемой ему юридической услуги.',
    },
    {
        title: 'ПРАКТИЧЕСКИЙ ОПЫТ И БЕЗУПРЕЧНАЯ РЕПУТАЦИЯ',
        subtitle: 'Наш богатый практический опыт позволяет нам эффективно решать любые проблемы наших клиентов, независимо от уровня их сложности.',
    },

]
export default prosData